import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/browser";
import config from "$lib/config/index.js";

const environment = config.siteUrl.includes("recette")
  ? "development"
  : "production";
Sentry.init({
  environment: environment,
  dsn: "https://0d4ccf6028abcf10668234d74c3288b9@o1006981.ingest.us.sentry.io/4507033973227520",
  integrations: [
    // Add browser profiling integration to the list of integrations
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "poc-svelte.zonesecure.org",
    "focus.zonesecure.org",
  ],

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
